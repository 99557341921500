"use client";

import type React from "react";
import { useIsWebview } from "../../../components/ApplicationState/hooks";
import skiAreaStyles from "./skiArea.module.scss";

type Props = {
    children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
    const isWebview = useIsWebview();

    return (
        <div
            className={skiAreaStyles.root}
            data-support-color-scheme-light-and-dark={isWebview}
        >
            {children}
        </div>
    );
};

export default Layout;
